import React from "react"
import { graphql, Link } from "gatsby"

import NewLayout from "../components/new_layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import Seo from "../components/seo"
import Certifications from "../components/v2023/Certifications/certifications"
import Identity from "../components/v2023/Identity/identity"
import Toolbar from "../components/v2023/UI/Card/toolbar"

const NewsArticle = ({ data, location, pageContext }) => {
    const lang = pageContext.langKey
    const post = data.post
    const elements = {
        p: ({ node, ...props }) => <p {...props} />,
        /*img: ({ node, ...props }) => {
            let fileIndex = post.childrenFile.findIndex(file => file.url === props.src)

            return (<GatsbyImage image={getImage(post.childrenFile[fileIndex])} alt={props.alt} />)
        },*/
        img: ({ node, ...props }) => <img src={`https://strapi.web.tecalis.com${props.src}`} alt={props.alt} />
    }

    let infoMetaData = post.publish_date

    // Removido por petición de Eva
    /*if (post.update_date) {
        infoMetaData += ` · ${updateTitle[lang]} ${post.update_date}`
    }*/

    if (post.reading_time) {
        infoMetaData += ` · ${post.reading_time}`
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={post.seo?.title}
                description={post.seo?.meta_description}
                translates={pageContext.translates}
                image={post.seo?.image_2?.localFile?.publicURL}

            />

            <main className="main">
                <div className="main__section main__section--100-post" data-aos="fade-up">
                    <div className="container">
                        {post.category && 
                            <div className="tag-group">
                                <div className="tag tag--lg">{post.category.title}</div>
                            </div>
                        }
                        <h1>{post.title}</h1>
                        <GatsbyImage alt={post.media_new.alternativeText} image={getImage(post.media_new.localFile)} />
                        <div className="post-detail">
                            <div className="post-detail__info">
                                <div className="post-detail__info__metadata">
                                    {infoMetaData}
                                </div>
                                <div className="post-detail__info__language">
                                    <div className="tabs tabs--pills">
                                        {pageContext.translates && Object.entries(pageContext.translates).map(([key, value]) => {
                                            return <Link to={value} className={`tabs__tab ${key === lang ? "tabs__tab--active" : ""}`} key={key}>{key}</Link>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <Toolbar lang={lang} title={post.title} url={location.href} />
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100-post-content" data-aos="fade-up">
                    <div className="container">
                        <div className="container__content">
                            <ReactMarkdown children={post.content} rehypePlugins={[rehypeRaw]} components={elements} />
                        </div>
                    </div>
                </div>
                <Certifications />
                <Identity lang={lang} />
            </main>
        </NewLayout>
    )
}


export default NewsArticle

export const newsArticleQuery = graphql`
    query ($id: String!, $langKey: String!, $dateFormat: String!) {
        post: strapiPosts ( id: { eq: $id } ) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL

                    }
                }
            }
            title
            description
            content
            resume
            media_new {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 992
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            publish_date(formatString: $dateFormat, locale: $langKey)
            update_date(formatString: $dateFormat, locale: $langKey)
            date_iso: publish_date(formatString: "YYYY-MM-DDThh:mm:ssTZD")
            category {
                title
            }
            reading_time
        }
        site {
            siteMetadata {
                title
                twitterHandle
            }
        }
    }
`
